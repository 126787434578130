import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import TitleText from '../../components/formlib/TitleText';
import ClientInfoFaceSheet from './ClientInfoFaceSheet';
import CommunicationFaceSheet from './CommunicationFaceSheet';
import ClientFilesFaceSheet from './ClientFilesFaceSheet';
import InsuranceFaceSheet from './InsuranceFaceSheet';
import UpcomingEventsFaceSheet from './UpcomingEventsFaceSheet';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { useNavigate } from 'react-router';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  ReferralResponseType,
  deleteSession,
  getAllReferrals,
  getFacesheetDetails,
  getListByRole,
  patchFacesheetDetails,
  syncOfflineData,
} from '../../services/configApi/Clients';
import {
  DateString,
  FaceSheetDemographicsType,
  FaceSheetType,
  IntakeCoordinatorsType,
  OptionType,
  ServiceCoordinatorsType,
} from '../../utils/type';
import {
  AlertColor,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import moment from 'moment';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import DurationPicker from '../../components/formlib/DurationPicker';
import {
  formatStringDateInDecimals,
  convertTimeDuration,
  formatTimeDurationInDecimals,
  datePickerMinDateMaxDateValidate,
} from '../../utils/dateUtil';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { CLIENTS_DASHBOARD_ROUTE_NAME } from '../../routes/Routing';
import ClientJourneyWrapper from './ClientJourneyWrapper';
import { createclientsyncobj, deleteData } from '../../services/Offline';
import TransitionFaceSheet from './TransitionFaceSheet';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { REFERRAL_ID_KEY } from '../../services/Constant';
import { getResponseDB } from '../../services/storageDB';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

import { ReactComponent as DownloadIcon } from '../../assets/images/offlineDownload.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/offlineDelete.svg';
import { ReactComponent as SyncIcon } from '../../assets/images/offlineSync.svg';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import CustomRadioButton, {
  RadioButtonData,
} from '../../components/formlib/CustomRadioButton';
import _ from 'lodash';
import { getFullNameWithMiddleName } from '../../utils/nameUtils';
import {
  Codes,
  getCodesAndDescription,
} from '../../services/configApi/codelist/diagnosticCodes';

const cardStyle = { background: 'white', borderRadius: '6px', padding: '16px' };

export interface FaceSheetFormType {
  intakeCoordinator: string | null;
  serviceCoordinator: string | null;
  referralDiagnosisCode: string | null;
  beginDate: DateString;
  rollOverDate: string | null;
  assessmentHours: string | null;
  noOfAssessmentHoursUsed: string | null;
  isSecondaryProgramServices: boolean;
  eicsConsentToAccessInsurance: number;
  consentToAccessStartDate: string | null;
}

const nullInitialValues: FaceSheetFormType = {
  intakeCoordinator: '',
  serviceCoordinator: '',
  referralDiagnosisCode: '',
  beginDate: null,
  rollOverDate: '',
  assessmentHours: '',
  noOfAssessmentHoursUsed: '',
  isSecondaryProgramServices: false,
  eicsConsentToAccessInsurance: 0,
  consentToAccessStartDate: dayjs().format('MM/DD/YYYY'),
};

const validationSchema = yup.object().shape({
  intakeCoordinator: yup.string().nullable(),
  serviceCoordinator: yup.string().nullable(),
  beginDate: yup.string().nullable(),
  rollOverDate: yup.string().nullable(),
  assessmentHours: yup.string().nullable(),
  noOfAssessmentHoursUsed: yup.string().nullable(),
  isSecondaryProgramServices: yup.boolean().default(false),
  eicsConsentToAccessInsurance: yup.number().default(0),
  consentToAccessStartDate: yup.string().nullable(),
});

const validateForm = (value: FaceSheetFormType) => {
  const errors: Partial<FaceSheetFormType> = {};

  if (datePickerMinDateMaxDateValidate(value.beginDate)) {
    errors.beginDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

export const eicsConcentValues: RadioButtonData[] = [
  { defaultLabel: 'Granted', labelId: 'grantedText', value: '1' },
  { defaultLabel: 'Denied', labelId: 'deniedText', value: '2' },
  { defaultLabel: 'Unknown', labelId: 'RiskFactor.Unknown', value: '0' },
];

const FaceSheetScreen = () => {
  const navigation = useNavigate();
  const rctl = useIntl();
  const clientId = localStorage.getItem('ClientId');
  const referralId = localStorage.getItem(REFERRAL_ID_KEY);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [faceSheetDetails, setFaceSheetDetails] = useState<FaceSheetType>();
  const [servicedialogFlag, setServiceDialogFlag] = useState(false);
  const [intakedialogFlag, setIntakeDialogFlag] = useState(false);
  const [formdialogFlag, setFormDialogFlag] = useState(false);
  const [faceSheetForm, setFaceSheetForm] =
    useState<FaceSheetFormType>(nullInitialValues);
  const formikRef = useRef<FormikProps<FaceSheetFormType>>(null);

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');

  const [selectedDphId, setSelectedDphId] = React.useState(referralId);

  const [serviceCoordinatorsList, setServiceCoordinatorsList] = useState<
    OptionType[]
  >([]);
  const [intakeCoordinatorsList, setIntakeCoordinatorsList] = useState<
    OptionType[]
  >([]);

  const [selectedCoordinator, setSelectedServiceCoordinator] = useState<
    OptionType | null | undefined
  >();

  const [selectedIntakeCoordinator, setSelectedIntakeCoordinator] = useState<
    OptionType | null | undefined
  >();

  setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

  const [dphIdValues, setDphIdValues] = useState<OptionType[]>([]);
  const [makedownload, setMakedownload] = useState(false);
  const [deletedownload, setDeleteMakedownload] = useState(false);
  const [sync, setSync] = useState(false);
  const { lookupsDemographics, updateDemographics } = useContext(
    LookUpContext,
  ) as LookUpContextType;

  const [selectedDiagnosisCodesList, setSelectedDiagnosisCodesList] =
    useState<OptionType | null>(null);
  const [diagnosisCodesList, setDiagnosisCodesList] = useState<OptionType[]>(
    [],
  );

  useEffect(() => {
    const handleDiagnosisDataGetAPI = async () => {
      toggleLoader(true);
      getCodesAndDescription()
        .then(async (response: Codes[]) => {
          const diagnosisCodeList: OptionType[] = [];
          if (response.length > 0) {
            response.forEach((codeDetails) => {
              diagnosisCodeList.push({
                id: codeDetails.code,
                label: codeDetails.isUnderlined
                  ? `${codeDetails.code} - * ${codeDetails.description}`
                  : `${codeDetails.code} - ${codeDetails.description}`,
              });
            });
            setDiagnosisCodesList(diagnosisCodeList);
            await fetchFaceSheetDetails(diagnosisCodeList);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    };

    handleDiagnosisDataGetAPI();
  }, []);

  useEffect(() => {
    if (formikRef.current?.values?.referralDiagnosisCode) {
      const data = diagnosisCodesList.filter((obj) => {
        const medicalDiagnosis =
          formikRef.current?.values?.referralDiagnosisCode || '';
        return obj.label.includes(medicalDiagnosis);
      });
      if (formikRef.current)
        formikRef.current.setFieldValue(
          'referralDiagnosisCode',
          data[0]?.label,
        );
    }
  }, [diagnosisCodesList]);

  useEffect(() => {
    if (makedownload) {
      toggleLoader(true);
      const handleFaceSheetList = async () => {
        await fetchFaceSheetDetails(diagnosisCodesList);
      };
      handleFaceSheetList();
    }
  }, [makedownload]);

  useEffect(() => {
    toggleLoader(true);
    const getAllReferralsBasedOnSelectedClient = async () => {
      await fetchRefferralDetails();
    };

    getAllReferralsBasedOnSelectedClient();
    makeDownloadAndSync();
  }, []);

  const fetchRefferralDetails = async () => {
    toggleLoader(true);
    if (clientId) {
      await getAllReferrals(clientId)
        .then(async (response: ReferralResponseType[]) => {
          if (response) {
            localStorage.setItem(
              'clientReferralDetails',
              JSON.stringify(response),
            );

            const referralDetails = JSON.stringify(response);

            if (referralDetails) {
              const parsedReferralDetails = JSON.parse(referralDetails);
              setDphIdValues(() => {
                return parsedReferralDetails.map((d: ReferralResponseType) => {
                  return {
                    label:
                      d.dphId === null || d.dphId === ''
                        ? 'Yet to receive'
                        : d.dphId,
                    id: d.referralId,
                  };
                });
              });
            }
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('clientLoadError');
            setToastrDefaultMessage('Failed to get client details');
          }
        });
    }
  };

  const makeDownloadAndSync = async () => {
    // const referralIdAdded = (await getResponseDB('referralIds')) as string[];
    // if (referralIdAdded) {
    //   if (referralIdAdded.length <= 2) {
    //     setMakedownload(true);
    //     setDeleteMakedownload(false);
    //     setSync(false);
    //   } else {
    //     setMakedownload(false);
    //   }
    //   const referralid: string = localStorage.getItem('referralId') || '';
    //   if (referralIdAdded.includes(referralid)) {
    //     setDeleteMakedownload(true);
    //     setSync(true);
    //     setMakedownload(false);
    //   }
    // } else {
    //   setMakedownload(true);
    //   setSync(false);
    //   setDeleteMakedownload(false);
    // }

    // // check if application is not running in localhost and disable all
    // if (
    //   window.location.hostname !== 'localhost' &&
    //   window.location.hostname !== '127.0.0.1'
    // ) {
    //   setMakedownload(false);
    //   setSync(false);
    //   setDeleteMakedownload(false);
    // }
    setMakedownload(false);
    setSync(false);
    setDeleteMakedownload(false);
  };

  const handleRolesList = (faceSheetResponseDetails: FaceSheetType) => {
    const handleServiceCoordinatorsList = async () => {
      const serviceCoordinatorsDetails = await getListByRole(
        'backend.role.service_coordinator',
      );
      const serviceCoList: OptionType[] = [];
      serviceCoordinatorsDetails.forEach((coordinator) => {
        serviceCoList.push({
          id: coordinator.id,
          label: `${coordinator.firstName} ${coordinator.lastName}`,
        });
      });
      setServiceCoordinatorsList(serviceCoList);

      const selected: OptionType | undefined = serviceCoList.find(
        (s) =>
          s.id === faceSheetResponseDetails.demographics.serviceCoordinator,
      );
      if (selected) {
        setSelectedServiceCoordinator(selected);
      } else {
        setSelectedServiceCoordinator(null);
      }
    };
    handleServiceCoordinatorsList();

    const handleIntakeCoordinatorsList = async () => {
      const intakeCoordinatorsDetails = await getListByRole(
        'backend.role.intake_coordinator',
      );
      const intakeCoList: OptionType[] = [];
      intakeCoordinatorsDetails.forEach((coordinator) => {
        intakeCoList.push({
          id: coordinator.id,
          label: `${coordinator.firstName} ${coordinator.lastName}`,
        });
      });
      setIntakeCoordinatorsList(intakeCoList);

      const selected: OptionType | undefined = intakeCoList.find(
        (s) => s.id === faceSheetResponseDetails.demographics.intakeCoordinator,
      );
      if (selected) {
        setSelectedIntakeCoordinator(selected);
      } else {
        setSelectedIntakeCoordinator(null);
      }
    };
    handleIntakeCoordinatorsList();
  };

  const handleServiceDailog = () => {
    if (
      faceSheetDetails &&
      faceSheetDetails.demographics.serviceCoordinatorHistory.length > 0
    )
      setServiceDialogFlag(true);
  };

  const handleIntakeDailog = () => {
    if (
      faceSheetDetails &&
      faceSheetDetails.demographics.intakeCoordinatorHistory.length > 0
    )
      setIntakeDialogFlag(true);
  };

  const handleFormDailog = async () => {
    await fetchFaceSheetDetails(diagnosisCodesList);
    setFormDialogFlag(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFormSubmit = (formSubmitValues: FaceSheetFormType) => {
    const assessmentHoursConversion = convertTimeDuration(
      formSubmitValues.assessmentHours,
      false,
    );
    const usedHoursConversion = convertTimeDuration(
      formSubmitValues.noOfAssessmentHoursUsed,
      false,
    );
    const updatedValues: FaceSheetFormType = {
      ...formSubmitValues,
      assessmentHours: assessmentHoursConversion,
      noOfAssessmentHoursUsed: usedHoursConversion,
      consentToAccessStartDate:
        formSubmitValues.consentToAccessStartDate !== null
          ? formSubmitValues.consentToAccessStartDate
          : dayjs().format('MM/DD/YYYY'),
    };

    const patchPayload = new Map();

    if (faceSheetDetails) {
      Object.keys(updatedValues).forEach((key) => {
        const formValue = updatedValues[key as keyof FaceSheetFormType];
        const demographicsValue =
          faceSheetDetails.demographics[key as keyof FaceSheetDemographicsType];
        if (formValue !== demographicsValue) {
          patchPayload.set(key, formValue);
        }
      });
    }

    if (!patchPayload.size) return;

    const payload = Object.assign(
      {},
      Object.fromEntries(patchPayload),
    ) as Partial<FaceSheetFormType>;
    if (payload.eicsConsentToAccessInsurance) {
      payload.consentToAccessStartDate = dayjs().format('MM/DD/YYYY');
    }

    if (payload) {
      if (clientId) {
        toggleLoader(true);
        patchFacesheetDetails(payload, clientId)
          .then(async (response) => {
            if (response) {
              await fetchFaceSheetDetails(diagnosisCodesList);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('updateSuccessMessage');
              setToastrDefaultMessage('Updated Successfully');
              setFormDialogFlag(false);
            }
            toggleLoader(false);
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('updateFailureMessage');
              setToastrDefaultMessage('Failed to update details');
            }
          });
      }
    }
  };

  const syncData = async () => {
    const referralid: string = localStorage.getItem('referralId') || '';
    const result = await createclientsyncobj(referralid);
    toggleLoader(true);
    syncOfflineData(result)
      .then(async (response) => {
        if (response) {
          const selectedProgram: string | null =
            localStorage.getItem('selectedProgram');
          let programId: string = '';
          if (selectedProgram !== null) {
            programId = JSON.parse(selectedProgram).id;
          }

          await deleteData(programId, referralid).then(async () => {
            const referralIds = (await getResponseDB(
              'referralIds',
            )) as string[];
            if (referralIds) {
              if (referralIds.length === 0) {
                nullifyToken();
              }
            } else {
              nullifyToken();
            }
            makeDownloadAndSync();
          });

          setOpen(true);
          setToastrVariable('success');
          setToastrId('synced');
          setToastrDefaultMessage('Data synced successfully');
        }
      })
      .catch(() => {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('syncfail');
        setToastrDefaultMessage('Failed to sync data');
      })
      .finally(() => {
        makeDownloadAndSync();
        toggleLoader(false);
      });
  };

  const fetchOfflineData = async () => {
    // toggleLoader(true);
    // await getOfflineData()
    //   .then(async (response) => {
    //     if (response) {
    //       await saveOfflineDataClient(response, referralId ? referralId : '');
    //       const offlineToken: string =
    //         localStorage.getItem('offlineToken') || '';
    //       localStorage.setItem('refreshToken', offlineToken);
    //       setSync(true);
    //       setMakedownload(false);
    //       setOpen(true);
    //       setToastrVariable('success');
    //       setToastrId('offlinedownload');
    //       setToastrDefaultMessage('Offline data downloaded');
    //     }
    //   })
    //   .catch((error) => {
    //     setOpen(true);
    //     setToastrVariable('error');
    //     const apiError = error as ApiError;
    //     setToastrId(apiError.id);
    //     setToastrDefaultMessage(apiError.message);
    //   })
    //   .finally(async () => {
    //     makeDownloadAndSync();
    //     toggleLoader(false);
    //   });
  };

  const deleteOfflineData = async () => {
    toggleLoader(true);

    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    let programId: string = '';
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
    }
    const referralid: string = localStorage.getItem('referralId') || '';
    const referralRemaining = (await getResponseDB('referralIds')) as string[];
    let sessionIntimation: boolean = false;
    if (referralRemaining && referralRemaining.length === 1) {
      sessionIntimation = true;
    }

    if (sessionIntimation) {
      await deleteSession()
        .then(async (response) => {
          if (response) {
            deleteReferralData(programId, referralid);
          } else {
            setOpen(true);
            setToastrVariable('error');
            setToastrId('deleteSession');
            setToastrDefaultMessage(
              "Couldn't deleted offline data, please try again later.",
            );
            toggleLoader(false);
          }
        })
        .catch(() => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('deleteSession');
          setToastrDefaultMessage(
            "Couldn't deleted offline data, please try again later.",
          );
          toggleLoader(false);
        });
    } else {
      deleteReferralData(programId, referralid);
    }
  };

  const deleteReferralData = async (programId: string, referralid: string) => {
    await deleteData(programId, referralid).then(async () => {
      const referralIds = (await getResponseDB('referralIds')) as string[];
      if (referralIds) {
        if (referralIds.length === 0) {
          nullifyToken();
        }
      } else {
        nullifyToken();
      }
      makeDownloadAndSync();
      setOpen(true);
      setToastrVariable('success');
      setToastrId('deletedownload');
      setToastrDefaultMessage('Deleted offline data');
      toggleLoader(false);
    });
  };

  const nullifyToken = () => {
    localStorage.setItem('accessToken', 'EXPIRE SESSION');
    localStorage.setItem('refreshToken', 'EXPIRE SESSION');
    localStorage.setItem('offlineToken', 'EXPIRE SESSION');
  };

  const fetchFaceSheetDetails = async (
    filteredDiagnosisCodeList: OptionType[],
  ) => {
    if (clientId) {
      toggleLoader(true);
      await getFacesheetDetails(clientId)
        .then((response) => {
          if (response) {
            // update the re-evaluation data
            const data = response;

            const journeyCounts: { [key: string]: number } = {};

            data.clientJourney.forEach((item) => {
              // Count the occurrences of each journey name
              journeyCounts[item.journeyName] =
                (journeyCounts[item.journeyName] || 0) + 1;

              // If it's the 2nd or subsequent occurrence of "backend.journey_name.evaluation", update to "backend.journey_name.re-evaluation"
              if (
                item.journeyName === 'backend.journey_name.evaluation' &&
                journeyCounts[item.journeyName] > 1
              ) {
                item.journeyName = 'backend.journey_name.re-evaluation';
              }
            });

            data.demographics.consentToAccessStartDate =
              response.demographics.consentToAccessStartDate === null &&
              response.demographics.eicsConsentToAccessInsurance === 0
                ? response.demographics.referralDate
                : response.demographics.consentToAccessStartDate;
            setFaceSheetDetails(data);
            updateDemographics(data.demographics);

            handleRolesList(response);

            if (response.demographics) {
              const convertedAssessmentHours = convertTimeDuration(
                response.demographics.assessmentHours,
                true,
              );
              const convertedUsedAssessmentHours = convertTimeDuration(
                response.demographics.noOfAssessmentHoursUsed,
                true,
              );
              setFaceSheetForm({
                intakeCoordinator: response.demographics.intakeCoordinator,
                serviceCoordinator: response.demographics.serviceCoordinator,
                referralDiagnosisCode:
                  response.demographics.diagnosis?.code || '',
                beginDate: response.demographics.beginDate,
                rollOverDate: response.demographics.rollOverDate,
                assessmentHours: convertedAssessmentHours,
                noOfAssessmentHoursUsed: convertedUsedAssessmentHours,
                isSecondaryProgramServices:
                  response.demographics.isSecondaryProgramServices,
                eicsConsentToAccessInsurance:
                  response.demographics.eicsConsentToAccessInsurance,
                consentToAccessStartDate:
                  response.demographics.consentToAccessStartDate === null &&
                  response.demographics.eicsConsentToAccessInsurance === 0
                    ? response.demographics.referralDate
                    : response.demographics.consentToAccessStartDate,
              });

              const selected: OptionType | undefined =
                filteredDiagnosisCodeList.find(
                  (d) => d.id === response.demographics.diagnosis?.code,
                );
              if (selected) setSelectedDiagnosisCodesList(selected);
            }
          }
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Client.error');
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  function getMaxValue(valueHrs: string | null) {
    if (valueHrs) {
      let hours;
      let minutes;
      if (valueHrs.includes('.')) {
        hours = valueHrs.split('.')[0];
        minutes = valueHrs.split('.')[1];
      } else {
        hours = valueHrs.split(':')[0];
        minutes = valueHrs.split(':')[1];
      }

      if (minutes === '00') {
        return { hour: +hours, minutes: 0 };
      } else if (minutes === '25') {
        return { hour: +hours, minutes: 25 };
      } else if (minutes === '50') {
        return { hour: +hours, minutes: 50 };
      } else if (minutes === '75') {
        return { hour: +hours, minutes: 75 };
      }
    }
    return { hour: 0, minutes: 0 };
  }

  return (
    <Box component="main">
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />

        {faceSheetDetails && (
          <Dialog open={servicedialogFlag} fullWidth maxWidth="sm">
            <DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <MediumTypography
                  labelid={'Facesheet.serviceCoordinators'}
                  sxProps={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '8px',
                  }}
                  defaultlabel="Service Coordinators"
                />
                <Close
                  style={{
                    marginLeft: 'auto', // Move the Close button to the right side
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setServiceDialogFlag(false)}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              {faceSheetDetails.demographics.serviceCoordinatorHistory.map(
                (eventProps: ServiceCoordinatorsType) => {
                  return (
                    <Box sx={{ marginY: '8px' }} key={eventProps.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#2A4241',
                          }}
                        >
                          {eventProps.firstName} {eventProps.lastName}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#97A6A5',
                          }}
                        >
                          {moment(eventProps.startTime, 'YYYY-MM-DD HH:mm:ss')
                            .utc(true)
                            .local()
                            .format('MMM DD, YYYY')}{' '}
                          -{' '}
                          {eventProps.endTime
                            ? moment(eventProps.endTime, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .local()
                                .format('MMM DD, YYYY')
                            : 'Present'}
                        </Typography>
                      </Box>
                      <Box
                        className="horizontalLine"
                        sx={{ marginY: '12px' }}
                      />
                    </Box>
                  );
                },
              )}
            </DialogContent>
          </Dialog>
        )}

        {faceSheetDetails && (
          <Dialog open={intakedialogFlag} fullWidth maxWidth="sm">
            <DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <MediumTypography
                  labelid={'Facesheet.intakeFacilitators'}
                  sxProps={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '8px',
                  }}
                  defaultlabel="Intake Facilitators"
                />
                <Close
                  style={{
                    marginLeft: 'auto', // Move the Close button to the right side
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIntakeDialogFlag(false)}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              {faceSheetDetails.demographics.intakeCoordinatorHistory.map(
                (eventProps: IntakeCoordinatorsType) => {
                  return (
                    <Box sx={{ marginY: '8px' }} key={eventProps.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#2A4241',
                          }}
                        >
                          {eventProps.firstName} {eventProps.lastName}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato-Regular',
                            color: '#97A6A5',
                          }}
                        >
                          {moment(eventProps.startTime, 'YYYY-MM-DD HH:mm:ss')
                            .utc(true)
                            .local()
                            .format('MMM DD, YYYY')}{' '}
                          -{' '}
                          {eventProps.endTime
                            ? moment(eventProps.endTime, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .local()
                                .format('MMM DD, YYYY')
                            : 'Present'}
                        </Typography>
                      </Box>

                      <Box
                        className="horizontalLine"
                        sx={{ marginY: '12px' }}
                      />
                    </Box>
                  );
                },
              )}
            </DialogContent>
          </Dialog>
        )}

        {faceSheetDetails && (
          <Dialog open={formdialogFlag} fullWidth maxWidth="md">
            <DialogTitle>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items vertically in the center
                }}
              >
                <Tooltip
                  title={`${getFullNameWithMiddleName(
                    faceSheetDetails.demographics.firstName
                      ? faceSheetDetails.demographics.firstName
                      : '',
                    faceSheetDetails.demographics.middleName
                      ? faceSheetDetails.demographics.middleName
                      : '',
                    faceSheetDetails.demographics.lastName
                      ? faceSheetDetails.demographics.lastName
                      : '',
                  )}${
                    faceSheetDetails.demographics.dphId
                      ? ` - (${faceSheetDetails.demographics.dphId})`
                      : ''
                  }`}
                  arrow
                >
                  <Box>
                    <MediumTypography
                      label={`${getFullNameWithMiddleName(
                        faceSheetDetails.demographics.firstName
                          ? faceSheetDetails.demographics.firstName
                          : '',
                        faceSheetDetails.demographics.middleName
                          ? faceSheetDetails.demographics.middleName
                          : '',
                        faceSheetDetails.demographics.lastName
                          ? faceSheetDetails.demographics.lastName
                          : '',
                      )}${
                        faceSheetDetails.demographics.dphId
                          ? ` - (${faceSheetDetails.demographics.dphId})`
                          : ''
                      }`}
                      sxProps={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginTop: '16px',
                        maxWidth: '500px',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Formik
                innerRef={formikRef}
                initialValues={faceSheetForm}
                enableReinitialize={true}
                validateOnChange
                validationSchema={validationSchema}
                validate={validateForm}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Box component="div">
                    <Grid
                      container
                      className="pt-sm"
                      direction={'row'}
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <AutocompleteSearch
                          className="consentAutoSearch"
                          labelId={'Facesheet.intakeFacilitator'}
                          defaultId="Intake Facilitator"
                          optionHintId={'nointakeDropdownTextId'}
                          sxProps={{
                            [`& .${autocompleteClasses.popupIndicator}`]: {
                              transform: 'none',
                            },
                          }}
                          data={intakeCoordinatorsList}
                          selectedValue={
                            selectedIntakeCoordinator
                              ? selectedIntakeCoordinator
                              : { id: '', label: '' }
                          }
                          onSelectItem={(value: OptionType | null) => {
                            if (value) {
                              setSelectedIntakeCoordinator(value);
                              setFieldValue('intakeCoordinator', value.id);
                            }
                          }}
                          disableLabel={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AutocompleteSearch
                          className="consentAutoSearch"
                          labelId={'Facesheet.serviceCoordinator'}
                          defaultId="Service Coordinator"
                          optionHintId={'noserviceDropdownTextId'}
                          sxProps={{
                            [`& .${autocompleteClasses.popupIndicator}`]: {
                              transform: 'none',
                            },
                          }}
                          data={serviceCoordinatorsList}
                          selectedValue={
                            selectedCoordinator
                              ? selectedCoordinator
                              : { id: '', label: '' }
                          }
                          onSelectItem={(value: OptionType | null) => {
                            if (value) {
                              setSelectedServiceCoordinator(value);
                              setFieldValue('serviceCoordinator', value.id);
                            }
                          }}
                          disableLabel={false}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={'row'}
                      className="mt-sm"
                      spacing={2}
                      sx={{ display: 'none' }}
                    >
                      <Grid item xs={4} lg={4}>
                        <DatePickerComponent
                          name="beginDate"
                          value={dayjs(values.beginDate) as Dayjs}
                          labelid="Facesheet.beginDate"
                          defaultlabelid={'Assessment Begin Date'}
                          handlechange={(date: Dayjs | null) => {
                            // const nextYear = dayjs(
                            //   new Date(date).setFullYear(
                            //     new Date(date).getFullYear() + 1,
                            //   ),
                            // ).format('MM/DD/YYYY');
                            const nextYear = dayjs(date)
                              .add(1, 'year')
                              .format('MM/DD/YYYY');
                            const formattedDate =
                              dayjs(date).format('MM/DD/YYYY');
                            if (date === null) {
                              formikRef.current?.setValues({
                                ...values,
                                beginDate: null,
                                rollOverDate: null,
                              });
                            } else {
                              formikRef.current?.setValues({
                                ...values,
                                beginDate: formattedDate as DateString,
                                rollOverDate: nextYear,
                              });
                            }
                          }}
                        />
                        {values.rollOverDate && (
                          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                            <MediumTypography
                              label={`${rctl.formatMessage({
                                id: 'Facesheet.rolloverDate',
                              })}:`}
                              sxProps={{
                                color: '#97A6A5',
                                fontSize: '12px',
                                mt: '4px',
                              }}
                            />
                            <MediumTypography
                              label={`${values.rollOverDate as string}`}
                              sxProps={{
                                color: '#2A4241',
                                fontSize: '12px',
                                ml: '4px',
                                mt: '4px',
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <DurationPicker
                          labelId="Facesheet.noOfAssessmentHoursText"
                          defaultLabel="Assessment Hrs"
                          maxDuration={{ hour: 20, minutes: 0 }}
                          duration={formatStringDateInDecimals(
                            values.assessmentHours,
                          )}
                          required={true}
                          interval={25}
                          setDuration={(duration) => {
                            setFieldValue(
                              'assessmentHours',
                              formatTimeDurationInDecimals(duration),
                            );
                            setFieldValue('noOfAssessmentHoursUsed', '0.00');
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <DurationPicker
                          labelId="Facesheet.usedAssessmentHoursText"
                          defaultLabel="Used Assessment Hrs"
                          maxDuration={getMaxValue(values.assessmentHours)}
                          duration={formatStringDateInDecimals(
                            values.noOfAssessmentHoursUsed,
                          )}
                          required={true}
                          interval={25}
                          setDuration={(duration) => {
                            setFieldValue(
                              'noOfAssessmentHoursUsed',
                              formatTimeDurationInDecimals(duration),
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className="pt-sm"
                      direction={'row'}
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <AutocompleteSearch
                          className="consentAutoSearch"
                          textDecoration={
                            selectedDiagnosisCodesList?.label.includes('- *')
                              ? 'underline'
                              : 'none'
                          }
                          labelId={'referralDiagnosisCodeText'}
                          defaultId="Referral Diagnosis Code"
                          optionHintId={'noBillingDiagnosisCodesFound'}
                          renderOption={(props, option: OptionType) => {
                            const parts = option.label.split(' - ');
                            let beforeHyphen = '';
                            let afterHyphen = '';

                            if (parts.length > 1) {
                              beforeHyphen = parts[0];
                              afterHyphen = parts?.slice(1)?.join(' - ');
                            } else {
                              beforeHyphen = option.label;
                            }

                            const underlineStyle = afterHyphen.includes('*')
                              ? 'underline'
                              : 'none';

                            return (
                              <li {...props}>
                                {parts.length > 1 ? (
                                  <>
                                    <span>{beforeHyphen} - </span>
                                    <span
                                      style={{
                                        textDecoration: underlineStyle,
                                      }}
                                    >
                                      {afterHyphen}
                                    </span>
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      textDecoration: underlineStyle,
                                    }}
                                  >
                                    {beforeHyphen}
                                  </span>
                                )}
                              </li>
                            );
                          }}
                          sxProps={{
                            [`& .${autocompleteClasses.popupIndicator}`]: {
                              transform: 'none',
                            },
                            backgroundColor: 'white',
                          }}
                          data={diagnosisCodesList}
                          selectedValue={
                            selectedDiagnosisCodesList
                              ? selectedDiagnosisCodesList
                              : { id: '', label: '' }
                          }
                          onSelectItem={(value: OptionType | null) => {
                            if (value === null) {
                              setSelectedDiagnosisCodesList(null);
                              setFieldValue('referralDiagnosisCode', '');
                            } else {
                              setSelectedDiagnosisCodesList(value);
                              setFieldValue('referralDiagnosisCode', value.id);
                            }
                          }}
                          disableLabel={false}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <CheckBoxComponent
                          ischecked={values.isSecondaryProgramServices}
                          labelid="secondaryProgramServiceText"
                          defaultlabelid="Secondary Program Service"
                          CheckHandleChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setFieldValue(
                              'isSecondaryProgramServices',
                              event.target.checked,
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={'row'}
                      spacing={2}
                      className="pt-sm"
                    >
                      <Grid item xs={12} lg={12}>
                        <Box>
                          <MediumTypography
                            labelid="eicsConsentToAccessInsuranceText"
                            defaultlabel="EICS Consent To Access Insurance"
                            sxProps={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '8px',
                            }}
                          />
                          <CustomRadioButton
                            data={eicsConcentValues}
                            value={values.eicsConsentToAccessInsurance.toString()}
                            onSelectItem={(value) => {
                              if (value !== null) {
                                setFieldValue(
                                  'eicsConsentToAccessInsurance',
                                  parseInt(value),
                                );
                                setFieldValue(
                                  'consentToAccessStartDate',
                                  dayjs().format('MM/DD/YYYY'),
                                );
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4} lg={4} sx={{ marginTop: '-8px' }}>
                        <DatePickerComponent
                          labelid="consentDateText"
                          defaultlabelid="Insurance Consent Date"
                          value={dayjs(values.consentToAccessStartDate)}
                          disabledDate={true}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{ marginTop: '24px' }}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <ButtonComponent
                        className="btn-primary btn-cancel alrt_Width_btn mr-md"
                        variantType="contained"
                        labelId={'cancelText'}
                        defaultLabelId={'Cancel'}
                        onClick={() => {
                          formikRef.current?.setValues(faceSheetForm, true);
                          setSelectedIntakeCoordinator(null);
                          setSelectedServiceCoordinator(null);
                          setFaceSheetForm(faceSheetForm);
                          setFormDialogFlag(false);
                        }}
                      />

                      <ButtonComponent
                        variantType="contained"
                        type="submit"
                        className="btn-primary btn-submit alrt_Width_btn"
                        labelId={'saveText'}
                        defaultLabelId={'Save'}
                        onClick={handleSubmit}
                        disabled={
                          _.isEqual(faceSheetForm, values) ? true : false
                        }
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        )}

        <Box className="rowContainer">
          <CustomBackArrow
            onClick={async () => {
              localStorage.removeItem('Demographics');
              if (lookupsDemographics) {
                updateDemographics({
                  ...lookupsDemographics,
                  firstName: '',
                  middleName: '',
                  lastName: '',
                  ageInMonths: null,
                  sex: null,
                  dateOfBirth: '',
                });
              }
              navigation(CLIENTS_DASHBOARD_ROUTE_NAME);
            }}
          />
          <Box
            component="div"
            className="ml-md"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center', // Center items vertically
              width: '100%',
            }}
          >
            <Box>
              <TitleText
                labelid="Facesheet.Titletext"
                defaultlabel="Facesheet"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {makedownload && (
                <Box
                  onClick={fetchOfflineData}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DownloadIcon style={{ marginRight: '8px' }} />
                  <MediumTypography
                    labelid={'offlinedownloadtxt'}
                    defaultlabel={'Download offline'}
                    sxProps={{
                      color: '#008C82',
                      fontSize: '14px',
                      textAlign: 'justify',
                      marginRight: '24px',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
              {deletedownload && (
                <Box
                  onClick={deleteOfflineData}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DeleteIcon style={{ marginRight: '8px' }} />
                  <MediumTypography
                    labelid={'offlinedeletetxt'}
                    defaultlabel={'Delete offline'}
                    sxProps={{
                      color: '#008C82',
                      fontSize: '14px',
                      textAlign: 'justify',
                      marginRight: '24px',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
              {sync && (
                <Box
                  onClick={syncData}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SyncIcon style={{ marginRight: '8px' }} />

                  <MediumTypography
                    labelid={'offlinesynctxt'}
                    defaultlabel={'Sync'}
                    sxProps={{
                      color: '#008C82',
                      fontSize: '14px',
                      textAlign: 'justify',
                      marginRight: '24px',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
              <MediumTypography
                labelid={'Facesheet.DPHID'}
                defaultlabel={'Enrollment ID'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '14px',
                  textAlign: 'justify',
                  marginRight: '5px',
                  whiteSpace: 'nowrap',
                }}
              />
              <DropdownComponent
                names={dphIdValues}
                labelid={''}
                value={selectedDphId ? selectedDphId : ''}
                defaultlabelid=""
                handleChange={async (e) => {
                  if (e) {
                    setSelectedDphId(e);
                    localStorage.setItem(REFERRAL_ID_KEY, e);
                    await fetchFaceSheetDetails(diagnosisCodesList);
                  }
                }}
              />
            </Box>
          </Box>
        </Box>

        {faceSheetDetails && (
          <Grid
            container
            columnSpacing={'12px'}
            rowSpacing={'12px'}
            sx={{ marginBottom: '24px' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                style={{
                  ...cardStyle,
                  padding: '0px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                }}
              >
                {faceSheetDetails && (
                  <ClientJourneyWrapper
                    data={faceSheetDetails.clientJourney}
                    ifspDueDate={faceSheetDetails.demographics.ifspDueDate}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box style={cardStyle}>
                {faceSheetDetails &&
                  serviceCoordinatorsList &&
                  intakeCoordinatorsList && (
                    <ClientInfoFaceSheet
                      data={faceSheetDetails}
                      handleServiceDailog={handleServiceDailog}
                      handleIntakeDailog={handleIntakeDailog}
                      handleFormDailog={handleFormDailog}
                      handleSSPProviders={() => ({})}
                      serviceCoordinatorsList={serviceCoordinatorsList}
                      intakeCoordinatorsList={intakeCoordinatorsList}
                    />
                  )}
              </Box>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <Box
                  style={{
                    ...cardStyle,
                    width: '50%',
                    marginTop: '12px',
                    marginRight: '12px',
                    height: '100%',
                    minHeight: '200px',
                    maxHeight: 'max-content',
                  }}
                >
                  {faceSheetDetails && (
                    <CommunicationFaceSheet
                      data={faceSheetDetails.communicationLogs}
                    />
                  )}
                </Box>
                <Box
                  style={{
                    ...cardStyle,
                    width: '50%',
                    marginTop: '12px',
                    height: '100%',
                    minHeight: '200px',
                    maxHeight: 'max-content',
                  }}
                >
                  {faceSheetDetails && (
                    <ClientFilesFaceSheet data={faceSheetDetails.clientFiles} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box
                style={{
                  ...cardStyle,
                  backgroundColor: '#00C6B8',
                  height: '250px',
                }}
              >
                {faceSheetDetails && (
                  <UpcomingEventsFaceSheet
                    data={faceSheetDetails.appointments}
                  />
                )}
              </Box>
              <Box style={{ ...cardStyle, marginTop: '12px', height: '250px' }}>
                {faceSheetDetails && (
                  <InsuranceFaceSheet data={faceSheetDetails.insurances} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box style={{ ...cardStyle }}>
                {faceSheetDetails && (
                  <TransitionFaceSheet data={faceSheetDetails.transition} />
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default FaceSheetScreen;
